/**======================================================================
=========================================================================
Template Name: Dashboard Bootstrap 5 Admin Template
Author: Rajodiya Infotech
Support: [support link]
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

@import "node_modules/bootstrap/scss/bootstrap";

// main framework
@import "themes/generic";
@import "themes/general";

// Layouts
@import "themes/layouts/dash-sidebar";
@import "themes/layouts/dash-header";
@import "themes/layouts/dash-common";

// widget
@import "themes/components/widget";

// Componants
@import "themes/components/avtar";
@import "themes/components/badge";
@import "themes/components/button";
@import "themes/components/dropdown";
@import "themes/components/card";
@import "themes/components/progress";
@import "themes/components/table";
@import "themes/components/tabs";
@import "themes/components/form";

// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/e-commerce";

// custom pluginss
@import "themes/plugins/sweatalert";
@import "themes/plugins/lightbox";
@import "themes/plugins/notification";
@import "themes/plugins/pnotify";
@import "themes/plugins/bar-rating";
@import "themes/plugins/modal";
@import "themes/plugins/rangeslider";
@import "themes/plugins/daterange";
@import "themes/plugins/tags-input";
@import "themes/plugins/maxlength";
@import "themes/plugins/wizard";
@import "themes/plugins/select2";
@import "themes/plugins/slider";
@import "themes/plugins/switches";
@import "themes/plugins/data-tables";
@import "themes/plugins/peitychart";
