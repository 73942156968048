// ============================
//     Table css start
// ============================

.table {
  &.table-align-center {
    td,
    th {
      vertical-align: middle;
    }
  }
  thead th {
    padding: 0.9rem 0.75rem;
  }
  td,
  th {
    vertical-align: middle;
  }
  &.table-borderless {
    td,
    th {
      border: none !important;
    }
  }
}
body .table thead th {
  font-size: 12px;
}
.table-hover tbody tr:hover {
  background-color: transparentize($primary, 0.97);
}
.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: middle;
}
.table {
  th {
    &.text-end[data-sortable] {
      padding-right: 16px;
      .dataTable-sorter::before,
      .dataTable-sorter::after {
        right: -14px;
      }
    }
  }
}
// .table-card .card-body,
// .table-body.card-body {
//     padding-left: 0;
//     padding-right: 0;
//     padding-top: 0;
// }
.dataTable-table {
  tfoot,
  thead,
  tbody {
    tr {
      th,
      td {
        padding: $table-cell-padding-y $table-cell-padding-x;
      }
    }
  }
}
.card:not(.table-card) {
  .dataTable-bottom,
  .dataTable-top {
    padding: $card-spacer-y $card-spacer-x;
  }
  .table-responsive {
    margin: -#{$card-spacer-y} -#{$card-spacer-x} 0 -#{$card-spacer-x};
    $temp: $card-spacer-x * 2;
    width: calc(100% + #{$temp});
  }
  .table {
    margin-bottom: 0;
    & > thead > tr > th {
      border-top: 0;
    }

    tr {
      td,
      th {
        &:first-child {
          padding-left: 25px;
        }

        &:last-child {
          padding-right: 25px;
        }
      }
    }

    &.without-header {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}
.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: none;
}
.dataTable-dropdown{
  label{
    display: flex;
    align-items: center;
    white-space: nowrap;
    select.dataTable-selector {
      width: 75px;
      margin-right: 10px;
    }
  }
}