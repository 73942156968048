// ============================
//     Progress css start
// ============================

.progress {
    overflow: visible;
    background: darken($body-bg, 4%);
    .progress-bar {
        border-radius: $progress-border-radius;
        @each $color, $value in $theme-colors {
            &.bg-#{$color} {
                box-shadow: 0 8px 8px -1px transparentize($value, 0.7);
            }
        }
    }
}
// Progress css end
