/* =====================  page start ======================================= */
.theme-avtar {
  width: 45px;
  height: 45px;
  border-radius: 17.3552px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
}
// project start
.conversations-list {
  .img-user {
    width: 45px;
    height: 45px;
    border-radius: $border-radius;
  }
  .list-group-item {
    cursor: pointer;
    padding: 16px 16px;
    border-radius: $border-radius;
    transition: all 0.1s ease-in-out;
    .btn-link {
      text-decoration: none !important;
      transition: all 0.1s ease-in-out;
    }
    &.active,
    &:hover {
      color: #fff;
      h6,
      p {
        color: #fff;
      }
      .text-primary,
      .btn-link {
        color: #fff !important;
      }
    }
  }
}
.horizontal-scroll-cards {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 6px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: shift-color($primary, $soft-bg-level);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}
.btn-addnew-project {
  border: 1px solid $border-color;
  border-radius: $border-radius-lg;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 24px);
  justify-content: center;
  .proj-add-icon {
    width: 35px;
    height: 35px;
    border-radius: $border-radius;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
// project end
// team start
.btn-addnew-team {
  text-align: center;
  .team-add-icon {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
.team-lists {
  text-align: center;
  > div {
    p {
      padding: 0 5px;
      margin-bottom: 0;
    }
  }
  img {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $primary;
    padding: 5px;
  }
}
.user-group {
  img {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border: 2px solid $card-bg;
    + img {
      margin-left: -14px;
    }
    &:hover {
      z-index: 5;
    }
  }
}
.team-msg {
  .img-user {
    width: 45px;
    height: 45px;
    border-radius: $border-radius;
  }
  .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
}
.form-send {
  position: relative;
  .form-control {
    position: relative;
    z-index: 1;
    padding-right: 60px;
  }
  .btn-send {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 5;
  }
}
// team end
.form-editor {
  .CodeMirror {
    padding-bottom: 0;
  }
  .CodeMirror,
  .CodeMirror-scroll {
    min-height: auto !important;
  }
}
// Timeline start
.timeline-wrapper {
  position: relative;
  flex-wrap: nowrap;
  text-align: center;
  overflow-x: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    height: 6px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: shift-color($primary, $soft-bg-level);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
  .timeline-icons {
    font-size: 30px;
    position: relative;
    z-index: 5;
    &:before,
    &:after {
      content: "";
      height: 2px;
      background: darken($body-bg, 5%);
      width: calc(50% - 16px);
      position: absolute;
      top: 22px;
      z-index: 1;
    }
    &:after {
      left: -12px;
    }
    &:before {
      right: -12px;
    }
    .timeline-dots {
      position: absolute;
      top: 22px;
      width: 50px;
      &:before,
      &:after {
        content: "";
        border: 3px solid darken($body-bg, 5%);
        position: absolute;
        top: -2px;
        z-index: 7;
        transform: rotate(45deg);
      }
      &:after {
        left: -6px;
      }
      &:before {
        right: -6px;
      }
    }
    i {
      display: inline-block;
      width: 50px;
      position: relative;
      z-index: 5;
    }
  }
  > div {
    &:first-child {
      .timeline-icons::after,
      .timeline-icons .timeline-dots::after {
        display: none;
      }
    }
    &:last-child {
      .timeline-icons::before,
      .timeline-icons .timeline-dots::before {
        display: none;
      }
    }
  }
}
// Timeline end
// kanban start
.kanban-wrapper {
  position: relative;
  flex-wrap: nowrap;
  .card {
    position: relative;
  }
  > .col {
    width: 390px;
    flex: 0 0 auto;
  }
}
// kanban end
// wizard start
.btn-chk-work {
  text-align: center;
  justify-content: center;
  .btn {
    margin-bottom: 14px;
    padding: 45px 50px;
    i {
      font-size: 55px;
    }
    @media screen and (max-width: 768px) {
      padding: 14px 16px;
      i {
        font-size: 40px;
      }
    }
  }
}
// wizard end
.event-cards {
  .card {
    padding: 12px;
    border-radius: 20px;
  }
}
// e-commerce start
.product-slider {
  .carousel-indicators {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    & [data-bs-target] {
      margin-left: 8px;
      margin-right: 8px;
    }
    img {
      width: 100%;
    }
  }
  img {
    border-radius: 15px;
  }
}
// e-commerce end
$i: 1;
@each $name, $value in $themes-colors {
  body {
    &.theme-#{$i} {
      .conversations-list {
        .list-group-item {
          .btn-link {
            color: map-get($value, "primary");
          }
          &.active,
          &:hover {
            background: map-get($value, "primary-grd"),
              map-get($value, "primary");
          }
        }
      }
      .team-lists {
        img {
          border-color: map-get($value, "primary");
        }
      }
      // ======================
    }
  }
  $i: $i + 1;
}
// / theme color scss end
/* ===================== Invoice page start ===================================== */
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.invoice-table.table {
  td {
    border: 0;
    padding: 4px 0;
  }
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;

  h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .invoice-order.table {
    padding-left: 0;

    th {
      border: 0;
      padding: 4px 0;

      &:first-child {
        padding-left: 0;
        width: 80px;
      }
    }
  }
}

.invoice-total {
  background: #f3f3f3;
  border-radius: 6px;
  padding: 14px 0;
  margin-bottom: 20px;
  .table {
    margin-bottom: 0;
    th {
      border: 0;
      padding: 4px 0;
      text-align: right;
    }

    td {
      text-align: right;
    }

    tbody {
      padding-right: 65px;
      float: right;
    }
  }
}

.invoice-summary {
  .label {
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 12px;
  }
}

.invoice-list {
  .btn {
    padding: 5px 10px;
    font-size: 12px;
  }

  .task-list-table {
    display: inline-block;
  }
}
// vr-info start
.vr-info-wrapper {
  background-size: cover;
  .vr-info-container {
    width: 70%;
    margin: 0 auto;
  }
  .card {
    background: transparentize($card-bg, 0.5%);
  }
}
// vr-info end
/* ===================== Invoice page end ======================================= */
// respinsive start
@media screen and (max-width: 768px) {
  .fc .fc-toolbar {
    flex-direction: column;
    .fc-toolbar-chunk {
      &.fc-toolbar-chunk {
        margin-top: 8px;
      }
    }
  }
}
// respinsive end
