.alert-dismissable .close,
.alert-dismissible .close {
    color: inherit;
    text-shadow: none;
}
@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }

    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }

    to {
        background-position: 0 0;
    }
}

.alert-inverse {
    background-color: #333;
    border-color: transparent;
    color: #fff;

    .close {
        position: relative;
        top: -2px;
        right: -21px;
        color: #fff;
        text-shadow: none;
    }

    .alert-link {
        color: #e6e6e6;
    }
}

.growl-animated {
    &.alert-inverse {
        box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
    }

    &.alert-info {
        box-shadow: 0 0 5px transparentize($primary, 0.5);
    }

    &.alert-success {
        box-shadow: 0 0 5px transparentize($success, 0.5);
    }

    &.alert-warning {
        box-shadow: 0 0 5px transparentize($warning, 0.5);
    }

    &.alert-danger {
        box-shadow: 0 0 5px transparentize($danger, 0.5);
    }
}

[data-notify="progressbar"] {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}
.notifier-container{
    z-index: 1029;
    font-family: $font-family-sans-serif;
    width: 400px;
    max-width: 98%;
    top: 15px;
}
.notifier{
    padding: calc(#{$card-spacer-y} - 5px) calc(#{$card-spacer-x} - 5px);
    border-radius: $border-radius;
    .notifier-title{
        font-size: $h5-font-size;
        font-weight: $headings-font-weight;
        margin-bottom: 2px;
    }
    .notifier-body{
        font-size: $font-size-base;
    }
    .notifier-img{
        .img {
            width: 40px;
            height: 40px;
        }
    }
    .notifier-close{
        &:focus,
        &:hover{
            color: $danger;
            background: transparent;
        }
    }
}
.notifier{
    @each $color, $value in $theme-colors {
        &.#{$color} {
            border-left-color: $value;
        }
    }
}
